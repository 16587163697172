import { DOCUMENT } from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  Inject,
  ElementRef,
  Renderer2,
  OnInit,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnChanges {

  @Input() title: string;
  @Input() showComponent: boolean;
  @Input() keyboard = true;
  @Input() backdrop = true;
  @Input() position = 'center';
  @Input() size = 'md';
  @Output() showComponentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() popupClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keyup.esc', ['$event']) handleKeyUp(event) {
    if (this.keyboard) {
      this.closePopup();
    }
  }

  // @HostListener('document:click', ['$event'])
  // handleClick(event) {
  //   console.log(this._elRef.nativeElement)
  //   console.log(event.target)
  //   if (this._elRef.nativeElement === event.target) {
  //     console.log('here')
  //   }

  // }

  constructor(
    private _elRef: ElementRef<HTMLElement>
  ) {}


  ngOnChanges(change) {
    if (this.showComponent) {
      if (change.showComponent && change.showComponent.currentValue !== change.showComponent.previousValue) {
        document.querySelector('body').classList.toggle('modal-popup-open');
        if (this.backdrop) {
          document.querySelector('body').classList.toggle('show-overlay');
        }
      }
    } else {
      document.querySelector('body').classList.remove('modal-popup-open');
      if (this.backdrop) {
        document.querySelector('body').classList.remove('show-overlay');
      }
    }
  }

  closePopup() {
    this.showComponent = false;
    this.showComponentChange.emit(this.showComponent);
    this.popupClosed.emit();
    if (this.position !== 'center') {
      document.querySelector('body').classList.toggle('aside-menu-hidden');
    }
    if (this.backdrop) {
      document.querySelector('body').classList.toggle('show-overlay');
    }
    document.querySelector('body').classList.remove('modal-popup-open');
  }

  backdropClick($event): void {
    if (this.backdrop === true && this._elRef.nativeElement === $event.target) {
      this.closePopup();
    }
  }


}
