import { Routes} from '@angular/router';

import { ContentLayoutComponent, UnauthorizedComponent, NotfoundComponent } from './layout';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from '@app/core';
import { AccessGuard } from '@app/shared/security';

export const AppRoutes: Routes = [
  {
    path: '', redirectTo: 'shipment', pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'settings',
        loadChildren: 'app/settings/settings.module#SettingsModule',
        canActivate: [AccessGuard],
        data: {
          acl: 'settings::settings'
        }
      },
      {
        path: 'shipment',
        loadChildren: 'app/shipment/shipment.module#ShipmentModule',
        canActivate: [AccessGuard],
        data: {
          acl: 'shipment::shipment'
        },
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
];
// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

