import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

// third party module import
import { ToastrModule } from 'ngx-toastr';

// custom module import
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LayoutModule } from '@app/layout/layout.module';


// custom component import
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { AppRoutes } from './app.routing';

// custom service import
import { ApiService, AuthService, SharedService } from './shared/services';
import { AccessGuard } from './shared/security';
import { AuthGuard } from '@app/core';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),

    // third-party modules
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),

    // core & shared
    CoreModule,
    SharedModule,

    // layout
    LayoutModule,
  ],
  providers: [
    AccessGuard,
    ApiService,
    AuthService,
    AuthGuard,
    SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
