import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[isValidNumber]'
})
export class IsValidNumberDirective {
  
  constructor(private _el: ElementRef,
    private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = String(this._el.nativeElement.value || '').replace(/[^0-9|.]*/g, '');
    const splitArray = initalValue.split('.');

    if (splitArray.length === 1) {
      this.control.control.setValue(splitArray[0].slice(0, 3));
    } else if (splitArray.length === 2 || splitArray.length === 3) {
      this.control.control.setValue(splitArray[0].slice(0, 3) + '.' + splitArray[1].slice(0, 2));
    }
  }

}