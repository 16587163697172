import { Injectable } from '@angular/core';
import { CanActivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         Router } from '@angular/router';

import {of as observableOf,  Observable } from 'rxjs';
import {catchError, map,  tap, take} from 'rxjs/operators';

import { AuthService } from '@app/shared/index';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.authService.isAuthenticated.pipe(map(
      response => {
        const idToken = this.authService.getToken();
        const tokenExpired = idToken ? this.authService.isTokenExpired(idToken) : true;
        if (response && !tokenExpired) {
          // logged in so return true
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }

}
