import {Injectable} from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable()

export class UtilityService {

  /**
   * Used to format date
   * @param currentDate
   */
  formatDate(currentDate, format = 'yyyy-MM-dd') {
    if (currentDate) {
      const date = new Date(currentDate);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }
    return currentDate;
  }
  
}
