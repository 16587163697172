
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  AutocompleteTriggerDirective,
  AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from './autocomplete-trigger-directive';
import { AutocompleteComponent } from './autocomplete';

import { AutocompleteOriginDirective } from './autocomplete-origin';
import { AutocompleteOptionComponent } from './autocomplete-option';

@NgModule({
  imports: [OverlayModule, CommonModule],
  exports: [
    AutocompleteComponent,
    AutocompleteTriggerDirective,
    AutocompleteOriginDirective,
    AutocompleteOptionComponent
  ],
  declarations: [
    AutocompleteComponent,
    AutocompleteTriggerDirective,
    AutocompleteOriginDirective,
    AutocompleteOptionComponent
  ],
  providers: [AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class AutocompleteModule {}
