import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[trimWhitespace]'
})

export class TrimWhitespaceDirective {

  constructor(
    private _el: ElementRef,
    private control: NgControl) { }

  @HostListener('focusout', ['$event']) onInputChange(event) {
    this.control.control.setValue(this._el.nativeElement.value.trim());
  }

}
