
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent, PaginationComponent } from './index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SpinnerModule, NgbModule],
  exports: [TableComponent, PaginationComponent],
  declarations: [TableComponent, PaginationComponent],
  entryComponents: [],
})
export class TableModule {}
