import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiService } from './api.service';
import { ToasterService } from './toaster.service';

@Injectable()
export class SharedService {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToasterService,
  ) {}

  handleError(e: any) {
    const detail =  (e && e.detail) ? e.detail : '';
    if (detail && detail === 'Signature has expired.') {
      this.router.navigate(['./login']);
    } else {
      if (e === 'Internal Server Error') {
        this.toastr.error(e);
      }
      return e;
    }
  }
}
