
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { StorageService } from '../services/storage.service';
import { ApiService } from '../services/api.service';
import {
  AccessChecker
} from './access-checker.service';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(
    public router: Router,
    private accessChecker: AccessChecker,
    private storage: StorageService,
    private apiService: ApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const acl = route.data.acl;

    if (this.storage.retrieve('acl', 'session')) {
      if (!this.accessChecker.isGranted(acl)) {
        this.router.navigate(['unauthorized']);
        return false;
      } else {
        return true;
      }
    } else {
      console.log('loading acl..');
      return this.apiService.get('users/acl').pipe(
      switchMap(
        res => {
          this.storage.store('acl', res, 'session');
          console.log('loaded acl..');
          if (res && res.includes(acl)) {
            return observableOf(true);
          } else {
            return observableOf(false);
          }
        }
      ));
    }
  }

}
