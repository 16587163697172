import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
  @Input() addressData: any;

//   className = 'badge-primary';

  constructor() { }

  ngOnInit() {
      if (!this.addressData) {
        throw new Error('Missing "addressData" property');
      }

    }
}
