import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormat implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value < 0) {
      const newVal = Math.abs(value);
      const tmp = newVal.toString().split('.');
      if (tmp.length === 1) {
        return '($' + Math.abs(value) + '.00)';
      } else {
        return '($' + Math.abs(value) + ')';
      }
    } else if (value === 0) {
      return '$0.00';
    } else {
      return '$' + value;
    }
  }
}
