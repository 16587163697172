import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/shared/index';
import { StorageService } from '@app/shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  formSubmitted: boolean;

  errors: any;
  invalidFormData: boolean;
  formattedErrors: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private router: Router,
    private authService: AuthService,
    private storage: StorageService) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      'grant_type': 'password',
    });
  }

  ngOnInit() {
    const target = this.el.nativeElement.querySelector('.form-control');
    if (target) {
      target.focus();
    }
  }

  // login controls
  get loginControls() {
    return this.loginForm.controls;
  }
  submitForm() {
    this.invalidFormData = true;
    if (this.loginForm.valid) {
      this.invalidFormData = false;
      const credentials = this.loginForm.value;
      this.formSubmitted = true;
      this.authService
      .attemptAuth('', credentials)
      .subscribe(
        data => {
          this.authService.getNavigation().subscribe(response => {
            this.authService.buildNavigation((response['top']));
            if (response['top'] && response['top'][0] && response['top'][0]['slug']) {
              const mainRoute = response['top'][0]['slug'];
              let redirectRoute = mainRoute;
              if (response['top'][0]['children'] && response['top'][0]['children']['left'] && response['top'][0]['children']['left'][0]) {
                const leftMainRoute = response['top'][0]['children']['left'][0];
                let childRoute = response['top'][0]['children']['left'][0]['slug'];
                redirectRoute += '/' + childRoute;
                if (leftMainRoute['children'] && leftMainRoute['children']['left'] && leftMainRoute['children']['left'][0]) {
                  childRoute = childRoute + '/' + leftMainRoute['children']['left'][0]['slug'];
                  redirectRoute += '/' + leftMainRoute['children']['left'][0]['slug'];
                }
                this.router.navigateByUrl(redirectRoute);
                this.storage.store('defaultRoute', redirectRoute, 'session');
              } else {
                this.router.navigateByUrl(mainRoute);
                this.storage.store('defaultRoute', mainRoute, 'session');
              }
            } else {
              this.storage.store('defaultRoute', 'dashboard', 'session');
              this.router.navigateByUrl('/');
            }
          });
        },
        err => {
          this.errors = err;
          this.formatError(err);
          this.formSubmitted = false;
        }
      );
    }
  }


  formatError(errorList) {
    this.formattedErrors = [];
    const parent = this;
    if (typeof errorList === 'object') {
      Object.keys(errorList).map(function(objectKey, index) {
        const value = errorList[objectKey];
          if (typeof errorList[objectKey] === 'string') {
            parent.formattedErrors.push(value);
          } else {
            if (value.length > 0) {
              parent.formattedErrors.push(value[0]);
            }
          }
      });
    } else {
      this.formattedErrors.push(errorList);
    }
  }

}
