import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { ContentLayoutComponent } from './';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './directives';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NotfoundComponent } from './notfound/notfound.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ContentLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    UnauthorizedComponent,
    NotfoundComponent
  ],
  exports: [

  ]
})
export class LayoutModule {}


