import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
})
export class StatusLabelComponent implements OnInit {
  @Input() name: string;
  @Input() code: string;

  className = 'badge-primary';

  constructor() { }

  ngOnInit() {
    // console.log(this.code, 'code Component', this.name, 'name component');

    if (!this.name) {
      throw new Error('Missing "name" property for icon component');
    }

    this.name = this.name.replace(/_/g, ' ');

    if (this.code === 'label-printed') {
      this.className = 'label-printing label-yellow';
    } else if (this.code === 'draft' ) {
      this.className = 'label-printing';
    } else if (this.code === 'unpaid') {
      this.className = 'label-printing unpaid';
    } else if (this.code === 'paid') {
      this.className = 'label-printing paid';
    } else if (this.code === 'arrived-la-hub') {
      this.className = 'label-printing label-arrived-la-ny';
    } else if (this.code === 'enroute-destination-hub' ) {
      this.className = 'label-printing enroute';
    } else if (this.code === 'carrier-picked-up') {
      this.className = 'label-printing career-pickup';
    } else if (this.code === 'picked-up') {
      this.className = 'label-printing career-pickup';
    } else if (this.code === 'pending') {
      this.className = 'label-printing unpaid';
    } else if (this.code === 'awaiting-pickup' ) {
      this.className = 'label-printing label-pickup-waiting';
    } else if (this.code === 'shipped') {
      this.className = 'label-printing career-pickup';
    } else if (this.code === 'enroute') {
      this.className = 'label-printing enroute';
    } else if (this.code === 'delivered') {
      this.className = 'label-printing label-delivered';
    } else if (this.code === 'label-created') {
      this.className = 'label-printing label-created';
    }  else if (this.code === 'enroute-ny-hub' ) {
      this.className = 'label-printing enroute';
    } else if (this.code === 'shipment-created' ) {
      this.className = 'label-printing shipment-created';
    }
  }
}


