import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-server-validation-error',
  templateUrl: './server-validation-error.component.html',
})
export class ServerValidationErrorComponent implements OnInit {
  @Input() errors: any;
  @Input() key: string | number;

  constructor() { }

  ngOnInit() {
    // console.log(this.errors, this.key)
  }

  isArray(obj) {
    return Array.isArray(obj)
  }

}
