import { EventEmitter, Component, OnChanges, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomValidator } from '@app/shared/custom-validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnChanges {
  @Input() userId: any;

  @Input() formSubmitted: boolean;

  /** Event emitter for popup close */
  @Output() cancelForm: EventEmitter<any> = new EventEmitter<any>();

  /** Event emitter for when form is submitted */
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  invalidForm: boolean;

  constructor(
    public fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), CustomValidator.password]],
      'confirmPassword': ['', [Validators.required]],
    }, {
      validator: CustomValidator.confirmPassword.bind(this)
    })
  }

  get password() { return this.form.get('password'); }
  get confirmPassword() { return this.form.get('confirmPassword'); }

  ngOnChanges(changes: SimpleChanges): void {}

  onSubmit(validPost) {
    this.invalidForm = true;
    if (this.form.valid) {
      this.invalidForm = false;
      this.formSubmit.emit(validPost);
    }
  }

  onCancel() {
    // cancel button event
    this.cancelForm.emit(true);
  }
}
