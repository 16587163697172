import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[isValidNumberWithNegative]'
})

export class IsValidNumberWithNegativeDirective {
  constructor(private _el: ElementRef, 
    private control: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event) {
    var initalValue = String(this._el.nativeElement.value || '')
    // const replacedValue = String(this._el.nativeElement.value || '').replace(/[-?|0-9][^0-9|.](?!.*[<>'"/;`%])/g, '');
    var replacedValue = initalValue.replace(/^[+-]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/g, '');
    // const replacedValue = initalValue;
    // const splitArray = initalValue.split('.');
    console.log("initial v", initalValue);
    console.log("replaced v", replacedValue);
    if (replacedValue.trim() === '' || replacedValue.trim() === '-' || replacedValue.trim() === '+' ) {
      if (parseFloat(initalValue) > 100 || parseFloat(initalValue) < -99) {
        initalValue = initalValue.slice(0, initalValue.length-1);
      }
      this.control.control.setValue(initalValue);
    }
    else {
      var oldValue = initalValue.slice(0, initalValue.length-1);
      if (parseFloat(oldValue) > 100) {
        oldValue = oldValue.slice(0, oldValue.length-1);
      }
      console.log("old value", oldValue);
      this.control.control.setValue(oldValue);
    }
    // if (splitArray.length === 1) {
    //   this.control.control.setValue(splitArray[0].slice(0, 3));
    // }
    // else if (splitArray.length === 2 || splitArray.length === 3) {
    //   this.control.control.setValue(splitArray[0].slice(0, 3) + '.' + splitArray[1].slice(0, 2));
    // }
  }
  
}
