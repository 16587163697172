import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { AccessChecker } from './access-checker.service';

@Directive({ selector: '[appIsGranted]'})
export class IsGrantedDirective implements OnDestroy {

  private alive = true;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessChecker: AccessChecker
  ) {}

  @Input() set appIsGranted([permission]: [string]) {

    const can = this.accessChecker.isGranted(permission);
      if (can && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!can && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
